import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function LogIn() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    user_id: '',
    user_pw: '',
  });

  // 폼 검증: 아이디와 비밀번호가 입력되었는지 확인
  const isFormValid = formData.user_id && formData.user_pw;

  // 입력값이 변경될 때마다 formData 상태 업데이트
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // 폼 제출 처리
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('http://thepolytranslator.com:8000/login/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: formData.user_id,
          user_pw: formData.user_pw,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('token', data.token);
        alert('로그인이 완료되었습니다!');
        navigate('/Home');
      } else {
        const errorData = await response.json();
        console.error('서버에서 받은 오류:', errorData);
        alert(`로그인에 실패하였습니다: ${errorData.message}`);
      }
    } catch (error) {
      console.error('네트워크 오류 또는 서버와의 연결 문제:', error);
      alert('로그인 중 오류가 발생했습니다. 나중에 다시 시도해주세요.');
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit} className="login-form">
        <div>
          <p>아이디: </p>
          <input
            type="text"
            name="user_id"
            value={formData.user_id}
            onChange={handleChange}
          />
        </div>
        <div>
          <p>비밀번호: </p>
          <input
            type="password"
            name="user_pw"
            value={formData.user_pw}
            onChange={handleChange}
          />
        </div>
        <button type="submit" disabled={!isFormValid}>로그인</button>
      </form>
    </div>
  );
}

export default LogIn;
