import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import WordCloud from 'react-wordcloud';

function Subtitle({ initialData = null }) {
  const navigate = useNavigate();
  const [translatedData, setTranslatedData] = useState(initialData);
  const [wordFrequencies, setWordFrequencies] = useState([]);

// 번역 데이터 가져오기
const fetchTranslatedData = async () => {
    try {
        const response = await fetch('http://thepolytranslator.com:8000/api/get_translated_data/');
        const data = await response.json();
        if (data.status === 'success') {
            setTranslatedData(data.translatedText);
        } else {
            console.error('Error:', data.message);
        }
    } catch (error) {
        console.error('번역 데이터를 불러오는 중 오류 발생:', error);
    }
};

// 단어 빈도수 가져오기
const fetchWordFrequency = async () => {
    try {
        const response = await fetch('http://thepolytranslator.com:8000/api/get_word_frequency/');
        const data = await response.json();
        if (data.status === 'success') {
            const words = data.word_frequency.map(([text, value]) => ({
                text,
                value,
            }));
            setWordFrequencies(words);
        } else {
            console.error('Error fetching word frequency:', data.message);
        }
    } catch (error) {
        console.error('워드클라우드 데이터를 불러오는 중 오류 발생:', error);
    }
};

  useEffect(() => {
    fetchTranslatedData();
    fetchWordFrequency();
  }, []);

  const handleDownload = async () => {
    try {
      const response = await fetch('http://thepolytranslator.com:8000/file/download-txt/', { method: 'GET' });
      if (!response.ok) throw new Error('파일 다운로드 실패');
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'translated_text.txt');
      document.body.appendChild(link);
      link.click();
      link.remove();
      await clearTranslatedData(); // 파일 다운로드 후 데이터 초기화
    } catch (error) {
      console.error('파일 다운로드 중 오류 발생:', error);
    }
  };

  const clearTranslatedData = async () => {
    try {
      const response = await fetch('http://thepolytranslator.com:8000/file/clear-data/', { method: 'POST' });
      if (!response.ok) throw new Error('데이터 초기화 실패');
      console.log('번역 데이터가 성공적으로 초기화되었습니다.');
      setTranslatedData(null);
      setWordFrequencies([]);
    } catch (error) {
      console.error('데이터 초기화 중 오류 발생:', error);
    }
  };

  const handleSubmit = async () => {
    await clearTranslatedData(); // 페이지 이동 전에 데이터 초기화
    navigate('/Home');
  };

  return (
    <div className="subtitle-page">
      {/* 첫 번째 subtitle-container */}
      <div className="subtitle-container">
        <div className="left-section">
          <h2>번역결과</h2>
          <div className="translated-result">
            {translatedData ? (
              translatedData.split('\n').map((line, index) => (
                <p key={index}>{line}</p>
              ))
            ) : (
              <p>로딩 중...</p>
            )}
          </div>
        </div>
      </div>

      {/* 두 번째 subtitle-container */}
      <div className="subtitle-container">
        <div className="right-section">
          <p className="text-1">번역이 완성되었습니다.</p>
          <div className="wordcloud-container">
            <WordCloud words={wordFrequencies} />
          </div>
          <p className="down-text">텍스트 파일로 다운로드 받으세요!</p>
          <div className="button-group">
            <button onClick={handleDownload} className="log-in-btn cusor down-btn">
              파일 다운로드 받기
            </button>
            <button onClick={handleSubmit} className="log-in-btn cusor down-btn down-btn2">
              다른 파일 번역하러 가기
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Subtitle;
