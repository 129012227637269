import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function SignUp() {
  const navigate = useNavigate(); // 페이지 이동을 위한 useNavigate 훅
  const [formData, setFormData] = useState({
    userName: '',  // 사용자 이름
    user_id: '',   // 사용자 ID (사용자명이 아님)
    user_pw: '',   // 비밀번호
    confirm_pw: '',  // 비밀번호 확인
    email: '',     // 이메일
    phone: '',     // 전화번호
  });

  // 모든 필드가 채워지고, 비밀번호와 비밀번호 확인 필드가 일치하는지 확인
  const isFormValid =
    formData.userName &&
    formData.user_id &&
    formData.user_pw &&
    formData.confirm_pw &&
    formData.email &&
    formData.phone &&
    formData.user_pw === formData.confirm_pw;  // 비밀번호와 비밀번호 확인 비교

  // 사용자가 입력하는 값을 상태에 반영
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,  // 기존 formData 복사
      [name]: value,  // 해당 입력 필드의 값을 업데이트
    });
  };

  // 폼이 제출되었을 때 실행되는 함수
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Django 서버로 회원가입 요청 보내기
      const response = await fetch('http://thepolytranslator.com:8000/signup/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: formData.user_id,    // 아이디 필드
          password: formData.user_pw,   // 비밀번호 필드
          email: formData.email,        // 이메일 필드
          phone: formData.phone,        // 전화번호 필드
          username: formData.userName,  // 사용자 이름 필드
        }),
      });
  
      if (response.ok) {
        alert('회원가입이 완료되었습니다!');
        navigate('/LogIn');
      } else if (response.status === 409) {
        alert('이미 존재하는 아이디입니다.');
      } else {
        const errorData = await response.json();
        console.error('서버에서 받은 오류:', errorData);
        alert(`회원가입에 실패하였습니다: ${errorData.message}`);
      }
    } catch (error) {
      console.error('네트워크 오류:', error);
      alert('회원가입 중 오류가 발생했습니다.');
    }
  };

  return (
    <>
      <p>회원가입을 위한 정보를 입력해주세요!</p>
      {/* 회원가입 폼 */}
      <form onSubmit={handleSubmit}>
        {/* 이름 입력 필드 */}
        <div className="log-in-box">
          <p>이름: </p>
          <input
            type="text"
            name="userName"
            value={formData.userName}
            onChange={handleChange}  // 값 변경 시 handleChange 함수 호출
          />
        </div>
        {/* 아이디 입력 필드 */}
        <div className="log-in-box">
          <p>아이디: </p>
          <input
            type="text"
            name="user_id"
            value={formData.user_id}
            onChange={handleChange}  // 값 변경 시 handleChange 함수 호출
          />
        </div>
        {/* 비밀번호 입력 필드 */}
        <div className="log-in-box">
          <p>비밀번호: </p>
          <input
            type="password"
            name="user_pw"
            value={formData.user_pw}
            onChange={handleChange}  // 값 변경 시 handleChange 함수 호출
          />
        </div>
        {/* 비밀번호 확인 필드 */}
        <div className="log-in-box">
          <p>비밀번호 확인: </p>
          <input
            type="password"
            name="confirm_pw"
            value={formData.confirm_pw}
            onChange={handleChange}  // 값 변경 시 handleChange 함수 호출
          />
        </div>
        {/* 이메일 입력 필드 */}
        <div className="log-in-box">
          <p>이메일: </p>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}  // 값 변경 시 handleChange 함수 호출
          />
        </div>
        {/* 전화번호 입력 필드 */}
        <div className="log-in-box">
          <p>전화번호: </p>
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}  // 값 변경 시 handleChange 함수 호출
          />
        </div>
        {/* 회원가입 버튼 */}
        <button
          className="log-in-btn cusor"
          type="submit"
          disabled={!isFormValid}  // 모든 필드가 채워지지 않으면 버튼 비활성화
        >
          회원가입
        </button>
      </form>
    </>
  );
}

export default SignUp;
